<template>
    <div class="flex flex-col justify-start items-center">
      <div class="flex flex-between mt-10 items-center" style="width : 1324px">
        <div style="height : 400px; width : 50%; overflow: hidden;" class="rounded">
          <LazyImage src="/pictures/conference-not-debate/rolex_public.jpg" alt="committee on EPFL logo" height="500px" />
        </div>
        <div class="flex flex-col justify-start items-center gap-7 w-1/2 p-5 px-20">
          <h1 class="text-4xl font-bold text-black">About Us</h1>
          <p class="text-lg"><pan class="text-red-primary">MUN EPFL</pan> is a Model United Nations association founded in 2004. The association is entirely managed by the École Polytechnique Fédérale de Lausanne and the University of Lausanne. Our mission is to defend and promote the ideals and principles of the United Nations by offering students the opportunity to engage in debate on a wide range of global issues. We are committed to empowering young people to become informed global citizens, well prepared to meet the challenges facing the world today. Through our programs and events, we cultivate students' critical thinking, public speaking and diplomatic skills.</p>
        </div>
      </div>

      <div class="flex flex-between mt-10 items-center" style="width : 1324px">
        <div class="flex flex-col justify-start items-center gap-7 w-1/2 p-5 px-20">
          <h2 class="text-4xl font-bold text-black">Weekly sessions</h2>
          <p class="text-lg">The weekly sessions aim to develop students' skills so that they can represent the association at conferences.
            During the first sessions of the semester, we review in detail the "Rules of Procedure", the rules of debate, while gradually initiating the debate.
            We are introducing a debate topic that will be maintained for 4 sessions. Members thus have time to learn as much as possible about the country they represent, and can develop alliance or offensive strategies as the sessions progress.
            The sessions mainly allow them to develop their speaking skills, including those of eloquence, negotiation and communication.
            At the beginning of the fourth session, some members may present resolutions, which represent a set of responses to the problem posed by the subject of the debate.
          </p>
        </div>
        <div style="height : 400px; width : 50%; overflow: hidden;" class="rounded">
          <LazyImage src="/pictures/conference-not-debate/welcoming_sg.jpg" alt="committee on EPFL logo" height="500px" />
        </div>
      </div>

      <div class="flex flex-between mt-10" style="width : 1324px">
        <div style="height : 400px; width : 50%; overflow: hidden;" class="rounded">
          <LazyImage src="/pictures/conferences/reunion_with_6_people.jpg" alt="committee on EPFL logo" height="500px" />
        </div>
        <div class="flex flex-col justify-start items-center gap-7 w-1/2 p-5 px-20">
          <h2 class="text-4xl font-bold text-black">One objective: represent MUN EPFL at conferences</h2>
          <p class="text-lg">Twice a semester, MUN EPFL goes on a weekend trip, in Switzerland or abroad, and goes to conferences of other Model United Nation associations</p>
          <p class="text-lg">Some members have the opportunity to take part in these trips, and to debate with students from all over the world</p>
          <p class="text-lg">The trip is partially financed by the association</p>

        </div>
      </div>

      <div class="bg-gray-200 w-full flex justify-center items-center mt-10 p-5">
        <div class="flex flex-col flex-between justify-center items-center" style="width : 1324px">
          <h2 class="text-4xl font-bold mb-5">A + for students</h2>
          
          <div class="flex items-stretch gap-5 justify-around">
            <div class="bg-gray-300 rounded p-3 w-1/3 flex-grow">
              <h3 class="text-2xl mb-2 text-left">Weekly seesions</h3>
              <p class="text-left">Every week, MUN EPFL members meet in a friendly setting to debate.</p>
            </div>

            <div class="bg-gray-300 rounded p-3 w-1/3 flex-grow">
              <h3 class="text-2xl mb-2 text-left">Develop oral skills</h3>
              <p class="text-left">Debates allow members to improve their eloquence, negotation techniques and communicaton skills.</p>
            </div>

            <div class="bg-gray-300 rounded p-3 w-1/3 flex-grow">
              <h3 class="text-2xl mb-2 text-left">International political issues</h3>
              <p class="text-left">Members have the opportunity to debate current and long-standing international political and geopolitical issues.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
  
  <script>

import LazyImage from '@/components/LazyImage.vue';

  export default {
    name: 'AboutPage',
    components: {
      LazyImage
    }
  }
  </script>