<template>
    <footer class="flex flex-col justify-center items-center mt-10 py-6 gap-6">
        <div class="flex items-center justify-center gap-3">
            <div>Numéro de téléphone</div>
            <div>info@mun-epfl.ch</div>
        </div>

        <div>La Coupole CO116, 1015 Lausanne Switzerland</div>

        <div class="flex items-center justify-center gap-10 font-bold text-xl underline">
            <a href="https://facebook.com/mun.epfl" target="_blank" rel="noopener noreferrer">Status</a>
            <a href="https://www.instagram.com/mun.epfl" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://facebook.com/mun.epfl" target="_blank" rel="noopener noreferrer">Facebook</a>
        </div>
    </footer>
</template>


<style>
    footer {
        border-top: 2px solid #ccc;
    }
</style>